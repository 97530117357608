import { analyticsPath } from "@constants/base-urls";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

const businessService = {
  fetchBusinessReviews: async (startDate, endDate, filterData) => {
    return await axiosInstance.get(`${analyticsPath}/reviews?${startDate}${endDate}${filterData}`);
  },
  resetReviewsCache: async () => {
    return await axiosInstance.get("/reviews/reset-cache");
  }
};

export default businessService;
