const Excited = () => {
  return (
    <svg width='65' height='65' viewBox='0 0 65 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M62.75 32.5C62.75 49.0685 49.3185 62.5 32.75 62.5C16.1815 62.5 2.75 49.0685 2.75 32.5C2.75 15.9315 16.1815 2.5 32.75 2.5C49.3185 2.5 62.75 15.9315 62.75 32.5Z'
        fill='#28C76F'
        fillOpacity='0.16'
        stroke='#10B981'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.7488 22.5007H22.7821'
        stroke='#10B981'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.7484 22.5007H42.7817'
        stroke='#10B981'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.4141 35.834C19.4141 43.1978 25.3836 49.1673 32.7474 49.1673C40.1112 49.1673 46.0807 43.1978 46.0807 35.834M46.0807 35.8346H19.4141H46.0807Z'
        fill='#F2F2F2'
      />
      <path
        d='M21.4141 35.834C21.4141 34.7294 20.5186 33.834 19.4141 33.834C18.3095 33.834 17.4141 34.7294 17.4141 35.834H21.4141ZM48.0807 35.834C48.0807 34.7294 47.1853 33.834 46.0807 33.834C44.9762 33.834 44.0807 34.7294 44.0807 35.834H48.0807ZM46.0807 37.8346C47.1853 37.8346 48.0807 36.9392 48.0807 35.8346C48.0807 34.7301 47.1853 33.8346 46.0807 33.8346V37.8346ZM19.4141 33.8346C18.3095 33.8346 17.4141 34.7301 17.4141 35.8346C17.4141 36.9392 18.3095 37.8346 19.4141 37.8346V33.8346ZM17.4141 35.834C17.4141 44.3024 24.279 51.1673 32.7474 51.1673V47.1673C26.4882 47.1673 21.4141 42.0932 21.4141 35.834H17.4141ZM32.7474 51.1673C41.2158 51.1673 48.0807 44.3024 48.0807 35.834H44.0807C44.0807 42.0932 39.0066 47.1673 32.7474 47.1673V51.1673ZM46.0807 33.8346H19.4141V37.8346H46.0807V33.8346Z'
        fill='#10B981'
      />
    </svg>
  );
};

export default Excited;
