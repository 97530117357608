import { createSlice } from "@reduxjs/toolkit";

export const appSignUpFormSlice = createSlice({
  name: "appSignUpForm",
  initialState: {
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
    notifications: false
  },
  reducers: {
    changeFormValue: (state, action) => {
      state[action.payload.field] = action.payload.value;
    }
  }
});

export const { changeFormValue } = appSignUpFormSlice.actions;

export default appSignUpFormSlice.reducer;
