import axios from "axios";
import { analyticsPath } from "@constants/base-urls";

// Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

// Service methods
const connectionService = {
  fetchListOfStatusAccounts: async () => {
    return await axiosInstance.get("/accounts");
  },

  connectAnalytics: async () => {
    return await axiosInstance.get("/accounts/google/analytics");
  },

  connectSeo: async () => {
    return await axiosInstance.get("/accounts/google/seo");
  },

  connectFacebook: async () => {
    return await axiosInstance.get("/accounts/facebook");
  },

  connectBusiness: async () => {
    return await axiosInstance.get("/accounts/google/reviews");
  },

  connectAccount: async code => {
    return await axiosInstance.put(`/accounts/google?code=${encodeURIComponent(code)}`);
  },

  connectFacebookAccount: async code => {
    return await axiosInstance.put(`/accounts/facebook?code=${encodeURIComponent(code)}`);
  },

  connectGoogleAds: async () => {
    return await axiosInstance.get("/accounts/google/ads");
  },

  connectGoogleOauth: async code => {
    return await axiosInstance.put(`/accounts/google?code=${encodeURIComponent(code)}`);
  },

  connectFacebookOauth: async code => {
    return await axiosInstance.put(`/accounts/facebook?code=${encodeURIComponent(code)}`);
  },

  connectFakeFacebookOauth: async () => {
    return await axiosInstance.put(`/accounts/facebook?code=demo`);
  },

  connectFakeGoogleOauth: async () => {
    return await axiosInstance.put(`/accounts/google?code=demo`);
  }
};

export default connectionService;
