import { handleError } from "@helpers/store-helper";
import { createSlice } from "@reduxjs/toolkit";
import { fetchMonthlyReport } from "./action";

const setReports = (state, action) => {
  state.errorMessage = "";
  state.isReportLoading = false;
  state.isFiltersReportLoading = false;

  state.operationalReport = action?.payload;
};

const setError = (state, action) => {
  state.isReportLoading = false;
  state.isFiltersReportLoading = false;

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

export const reporting = createSlice({
  name: "appOpertationReporting",
  initialState: {
    errorMessage: "",
    isReportLoading: false,
    isFiltersReportLoading: false,
    operationalReport: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMonthlyReport.pending, (state, action) => {
        state.errorMessage = "";
        state.isReportLoading = true;
        state.isFiltersReportLoading = true;
      })
      .addCase(fetchMonthlyReport.fulfilled, setReports)
      .addCase(fetchMonthlyReport.rejected, setError);
  }
});

export default reporting.reducer;
