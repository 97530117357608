import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "next/image";
// import promo from '@public/images/icons/promo.svg';
import React from "react";
import Icon from "@core/components/icon";
import IconButton from "@mui/material/IconButton";

const SuccessMessage = props => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          width: "100%",
          justifyContent: "end",
          marginBottom: "-20px",
          marginLeft: "10px"
        }}
      >
        <IconButton onClick={props.close} sx={{ backgroundColor: "transparent!important" }}>
          <Icon icon='tabler:x' fontSize={16} />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box>
          <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='26' height='26' rx='6' fill='#28C76F' fill-opacity='0.08' />
            <path
              d='M7.75 13L11.5 16.75L19 9.25'
              stroke='#28C76F'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Box>

        <Box sx={{ marginLeft: "12px" }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: "15px", lineHeight: "22px", marginRight: "4px" }}>
              {props.title}
            </Typography>
            🎉
          </Box>

          <Typography sx={{ fontWeight: "450", fontSize: "13px", lineHeight: "20px", marginTop: "4px" }}>
            {props.text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessMessage;
