import { LOCATION_NOT_SELECTED } from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { fetchBusinessLocations } from "../auth";
import businessService from "./service";

export const fetchBusiness = createAsyncThunk(
  "appBusiness/fetchBusiness",
  async ({ date = { start: 0, end: 0 }, provider }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment.utc(start).format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment.utc(end).format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";
      const filterData = provider === "all" || !provider ? "&data[]=google&data[]=yelp" : `&data[]=${provider}`;

      if (start || end) {
        const response = await businessService.fetchBusinessReviews(startDate, endDate, filterData);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message === LOCATION_NOT_SELECTED) {
        dispatch(fetchBusinessLocations());
      }
      return rejectWithValue(error);
    }
  }
);

// Thunk reset analytics cache
export const resetReviewsCache = createAsyncThunk(
  "appBusiness/resetReviewsCache",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await businessService.resetReviewsCache();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
