import toast from "react-hot-toast";
import {
  COMPANY_NOT_SELECTED,
  FACEBOOK_TOKEN_EXPIRED,
  LOCATION_NOT_FOUND,
  LOCATION_NOT_SELECTED,
  NOT_LOGGED_IN,
  PAGE_NOT_FOUND,
  PASSWORD_NOT_SELECTED,
  PROPERTY_NOT_FOUND,
  SOCIAL_NOT_SELECTED,
  YOU_DO_NOT_WORK_AT_THIS_COMPANY
} from "../constants/response-messages";

export const defaultExcludedMessages = [
  LOCATION_NOT_SELECTED,
  PASSWORD_NOT_SELECTED,
  COMPANY_NOT_SELECTED,
  SOCIAL_NOT_SELECTED,
  LOCATION_NOT_FOUND,
  PROPERTY_NOT_FOUND,
  PAGE_NOT_FOUND
];

// Set to track active toasts
const activeToasts = new Set();

export const handleError = (message, statusCode, excludedMessages = defaultExcludedMessages) => {
  // Normalize the path to remove any trailing slash
  const currentPath = window.location.pathname.replace(/\/$/, "");

  // Handle specific errors with redirects
  if (
    (message === COMPANY_NOT_SELECTED || message === YOU_DO_NOT_WORK_AT_THIS_COMPANY) &&
    currentPath !== "/select-company"
  ) {
    window.location.replace("/select-company");
    return;
  }

  if (message === FACEBOOK_TOKEN_EXPIRED && currentPath !== "/account-settings/connections") {
    window.location.replace("/account-settings/connections");
    return;
  }

  if (statusCode === 401 && message === NOT_LOGGED_IN) {
    window.location.replace("/login");
    return;
  }

  // Suppress toasts on /account-settings/connections
  if (
    currentPath !== "/account-settings/connections" &&
    (statusCode === 500 || (message && !excludedMessages.includes(message)))
  ) {
    if (!activeToasts.has(message)) {
      activeToasts.add(message);
      toast.error(message || "Something went wrong.", {
        duration: 2000,
        onClose: () => activeToasts.delete(message) // Remove from activeToasts when toast closes
      });
    }
  }
};

export const openCenteredWindow = (url, name, width, height) => {
  // Ensure the window doesn't open off-screen
  const left = Math.max(0, window.screen.width / 2 - width / 2);
  const top = Math.max(0, window.screen.height / 2 - height / 2);

  window.open(url, name, `width=${width},height=${height},left=${left},top=${top}`);
};
