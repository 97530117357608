// src/slices/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchSocialMedia, resetSocialMediaCache } from "./action";
import { handleError } from "@helpers/store-helper";

const setSocialMedia = (state, action) => {
  state.errorMessage = "";
  state.isSocialLoading = false;
  state.isFiltersSocialLoading = false;

  state.socialMedia = action?.payload;
  state.fbInsights = action?.payload?.find(item => item?.header === "fb-insights");
  state.igInsights = action?.payload?.find(item => item?.header === "ig-insights");
  state.bothInsights = action?.payload?.find(item => item?.header === "both-insights");
  state.fbPosts = action?.payload?.find(item => item?.header === "fb-posts");
  state.igPosts = action?.payload?.find(item => item?.header === "ig-posts");
  state.bothPosts = action?.payload?.find(item => item?.header === "both-posts");
};

const setError = (state, action) => {
  state.isSocialLoading = false;
  state.isFiltersSocialLoading = false;

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

export const appSocialMediaSlice = createSlice({
  name: "appSocialMedia",
  initialState: {
    errorMessage: "",
    isSocialLoading: false,
    isFiltersSocialLoading: false,
    socialMedia: [],
    fbInsights: [],
    bothInsights: [],
    igInsights: [],
    igPosts: [],
    fbPosts: [],
    bothPosts: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSocialMedia.fulfilled, setSocialMedia)
      .addCase(fetchSocialMedia.pending, (state, action) => {
        state.errorMessage = "";
        state.isSocialLoading = true;
        state.isFiltersSocialLoading = true;
      })
      .addCase(fetchSocialMedia.rejected, setError)
      .addCase(resetSocialMediaCache.fulfilled, (state, action) => {
        state.isSocialLoading = false;
        state.isFiltersSocialLoading = false;
      })
      .addCase(resetSocialMediaCache.pending, (state, action) => {
        state.errorMessage = "";
        state.isSocialLoading = true;
        state.isFiltersSocialLoading = true;
      })
      .addCase(resetSocialMediaCache.rejected, setError);
  }
});

export default appSocialMediaSlice.reducer;
