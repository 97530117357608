import { analyticsPath } from "@constants/base-urls";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

const reportingService = {
  fetchWeeklyReport: async date => {
    return await axiosInstance.get(`/operationals/reports/weekly?date=${date}`);
  },

  fetchMonthlyReport: async (year, month) => {
    return await axiosInstance.get(`/operationals/reports/monthly?year=${year}&month=${month}`);
  },

  fetchQuarterlyReport: async (year, quarter) => {
    return await axiosInstance.get(`/operationals/reports/quarterly?year=${year}&quarter=${quarter}`);
  },

  fetchYearlyReport: async year => {
    return await axiosInstance.get(`/operationals/reports/yearly?year=${year}`);
  },

  fetchCustomReport: async (currentStartDate, currentEndDate, previousStartDate, previousEndDate) => {
    return await axiosInstance.get(
      `/operationals/reports/custom?currentStartDate=${currentStartDate}&currentEndDate=${currentEndDate}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`
    );
  },
  printReport: async () => {
    return await axiosInstance.post(`/operationals/reports/print`);
  }
};

export default reportingService;
