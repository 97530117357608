// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import advertisment from "@store/apps/advertisment/slice";
import reviews from "@store/apps/ai/slice";
import analytics from "@store/apps/analytics/slice";
import auth from "@store/apps/auth";
import dataEntryForm from "@store/apps/auth/daily-data-entry";
import caseManagement from "@store/apps/cases/slice";
import connections from "@store/apps/connections/slice";
import mapBox from "@store/apps/mapbox";
import operational from "@store/apps/operational";
import onboardingForm from "@store/apps/operational/onboarding";
import reporting from "@store/apps/reporting/slice";
import business from "@store/apps/reviews/slice";
import roles from "@store/apps/roles";
import signUpForm from "@store/apps/signup";
import socialMedia from "@store/apps/social-media/slice";
import daysFilter from "@store/apps/sort";

export const store = configureStore({
  reducer: {
    daysFilter,
    onboardingForm,
    dataEntryForm,
    operational,
    analytics,
    connections,
    socialMedia,
    mapBox,
    roles,
    business,
    auth,
    reporting,
    signUpForm,
    advertisment,
    caseManagement,
    reviews
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
