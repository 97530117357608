import { handleError } from "@helpers/store-helper";
import { createSlice } from "@reduxjs/toolkit";
import { fetchBusiness, resetReviewsCache } from "./action";

const setError = (state, action) => {
  state.status = false;
  state.isLoading = false;
  state.isBusinessLoading = false;
  state.isBusinessFiltersLoading = false;

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

const setBusinessData = (state, action) => {
  state.isBusinessLoading = false;
  state.isBusinessFiltersLoading = false;
  state.errorMessage = "";
  state.business = {
    reviews: action.payload?.reviews,
    analytics: action.payload?.analytics,
    header: action.payload?.analytics?.[0]?.header,
    reviewsReports: action.payload?.analytics?.[0]?.reports?.find(item => item?.name === "reviews"),
    ratingReports: action.payload?.analytics?.[0]?.reports?.find(item => item?.name === "ratings"),
    reviewsGraph: action.payload?.analytics?.[0]?.graphs?.find(item => item?.name === "reviews")?.data,
    ratingsGraph: action.payload?.analytics?.[0]?.graphs?.find(item => item?.name === "ratings")?.data
  };
};

export const appBusinessSlice = createSlice({
  name: "appBusiness",
  initialState: {
    redirectUrl: "",
    business: [],
    isBusinessFiltersLoading: false,
    isBusinessLoading: false,
    status: null,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBusiness.fulfilled, setBusinessData)
      .addCase(fetchBusiness.pending, (state, action) => {
        state.isBusinessLoading = true;
        state.errorMessage = "";
        state.isBusinessFiltersLoading = true;
      })
      .addCase(fetchBusiness.rejected, setError)
      .addCase(resetReviewsCache.fulfilled, (state, action) => {
        state.isBusinessLoading = false;
        state.isBusinessFiltersLoading = false;
      })
      .addCase(resetReviewsCache.pending, (state, action) => {
        state.isBusinessLoading = true;
        state.isBusinessFiltersLoading = true;
        state.errorMessage = "";
      })
      .addCase(resetReviewsCache.rejected, setError);
  }
});

export default appBusinessSlice.reducer;
