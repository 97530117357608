import { analyticsPath } from "@constants/base-urls";
import axios from "axios";

// Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

// Service methods
const advertismentService = {
  fetchGoogleAdvertisers: async () => {
    return await axiosInstance.get("/ads/advertisers/google");
  },

  selectGoogleAdvertiser: async advertiserId => {
    return await axiosInstance.post("/ads/advertisers/google", { advertiserId });
  },
  fetchFacebookAdvertisers: async () => {
    return await axiosInstance.get("/ads/advertisers/facebook");
  },

  selectFacebookAdvertiser: async advertiserId => {
    return await axiosInstance.post("/ads/advertisers/facebook", { advertiserId });
  },

  fetchAdsData: async (startDate, endDate, dataParams) => {
    return await axiosInstance.get(`/ads?${startDate}${endDate}${dataParams}`);
  },

  resetAdCache: async () => {
    return await axiosInstance.get("/analytics/reset-cache");
  }
};

export default advertismentService;
