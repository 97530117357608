// import { createSlice } from "@reduxjs/toolkit";
// import { fetchAdsData, fetchFacebookAdvertisers, fetchGoogleAdvertisers, fetchKeywords } from "./action";

// const setAdsData = (state, action) => {
//   state.isAdsDataLoading = false;
//   state.adsAnalyticsData = action?.payload?.analytics;
//   state.adsCampaignData = action?.payload?.campaigns;
//   state.adsKeywordsData = action?.payload?.keywords;
//   state.adsData = action?.payload;
// };

// // const setError = (state, action) => {
// //   state.status = false;
// //   state.isLoading = false;
// //   state.isFiltersLoading = false;
// //   state.isAccountConnected = false;
// //   state.isCompanyUpdatedSuccess = false;
// //   state.analyticsError = true;

// //   const message = action?.payload?.response?.data?.message;
// //   const statusCode = action?.payload?.response?.data?.statusCode;

// //   state.errorMessage = message;

// //   handleError(message, statusCode);
// // };

// export const appConnectionsSlice = createSlice({
//   name: "appConnections",
//   initialState: {
//     googleAdvertisers: {},
//     facebookAdvertisers: {},
//     adsData: {},
//     adsAnalyticsData: {},
//     adsCampaignData: {},
//     adsKeywordsData: {},
//     isAdsDataLoading: false
//   },
//   reducers: {},
//   extraReducers: builder => {
//     builder
//       .addCase(fetchGoogleAdvertisers.fulfilled, (state, action) => {
//         state.googleAdvertisers = action.payload;
//       })
//       .addCase(fetchGoogleAdvertisers.pending, state => {})
//       .addCase(fetchGoogleAdvertisers.rejected, state => {})
//       .addCase(fetchFacebookAdvertisers.fulfilled, (state, action) => {
//         state.facebookAdvertisers = action.payload;
//       })
//       .addCase(fetchFacebookAdvertisers.pending, state => {})
//       .addCase(fetchFacebookAdvertisers.rejected, state => {})
//       .addCase(fetchAdsData.fulfilled, setAdsData)
//       .addCase(fetchAdsData.pending, state => {
//         state.isAdsDataLoading = true;
//       })
//       .addCase(fetchAdsData.rejected, state => {
//         state.isAdsDataLoading = false;
//       })
//       .addCase(fetchKeywords.pending, state => {})
//       .addCase(fetchKeywords.rejected, state => {})
//       .addCase(fetchKeywords.fulfilled, (state, action) => {
//         state.adsKeywordsData = action.payload;
//       });
//   }
// });

// export default appConnectionsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchListOfStatusAccounts,
  connectAnalytics,
  connectSeo,
  connectBusiness,
  connectAccount,
  connectFacebookAccount,
  connectGoogleOauth,
  connectFacebookOauth,
  connectFakeGoogleOauth,
  connectFakeFacebookOauth
} from "./action";
import { handleError } from "@helpers/store-helper";

export const appConnectionsSlice = createSlice({
  name: "connections",
  initialState: {
    statusAccounts: null,
    isLoading: false,
    redirectUrl: "",
    accounts: {},
    isAccountLoading: true,
    connectionLoading: false,
    isAccountConnected: false,
    isCompanyUpdatedSuccess: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchListOfStatusAccounts.fulfilled, (state, action) => {
        state.error = "";
        state.isAccountLoading = false;
        state.analyticsError = false;
        state.accounts = action.payload;
      })
      .addCase(fetchListOfStatusAccounts.pending, state => {
        state.error = "";
        state.isAccountLoading = true;
        state.analyticsError = false;
      })
      .addCase(fetchListOfStatusAccounts.rejected, (state, action) => {
        state.status = false;
        state.isLoading = false;
        state.isAccountLoading = false;
        state.isAccountConnected = false;
        state.isCompanyUpdatedSuccess = false;
        state.errorMessage = action?.payload?.message;
      })

      // Handling connectAnalytics
      .addCase(connectAnalytics.fulfilled, (state, action) => {
        state.error = "";
        state.redirectUrl = action.payload;
      })
      .addCase(connectAnalytics.pending, (state, action) => {
        state.error = "";
        state.redirectUrl = "";
        state.isLoading = true;
      })
      .addCase(connectAnalytics.rejected, setError)

      // Handling connectSeo
      .addCase(connectSeo.fulfilled, (state, action) => {
        state.error = "";
        state.redirectUrl = action.payload;
      })
      .addCase(connectSeo.pending, (state, action) => {
        state.error = "";
        state.redirectUrl = "";
      })
      .addCase(connectSeo.rejected, setError)

      // Handling connectBusiness
      .addCase(connectBusiness.fulfilled, (state, action) => {
        state.redirectUrl = action.payload;
      })
      .addCase(connectBusiness.pending, (state, action) => {
        state.isLoading = true;
        state.redirectUrl = "";
      })
      .addCase(connectBusiness.rejected, setError)

      // Handling connectAccount
      .addCase(connectAccount.fulfilled, (state, action) => {
        state.error = "";
        state.isAccountConnected = true;
      })
      .addCase(connectAccount.pending, (state, action) => {
        state.error = "";
        state.isAccountConnected = false;
      })
      .addCase(connectAccount.rejected, setError)

      // Handling other connection actions similarly
      .addCase(connectFacebookAccount.fulfilled, (state, action) => {
        state.error = "";
        state.isAccountConnected = true;
        state.connectionLoading = false;
      })
      .addCase(connectFacebookAccount.pending, (state, action) => {
        state.error = "";
        state.isAccountConnected = false;
        state.connectionLoading = true;
      })

      .addCase(connectFacebookAccount.rejected, setError)

      // .addCase(connectGoogleOauth.pending, state => {
      //   state.isLoading = true;
      //   state.errorMessage = "";
      // })
      // .addCase(connectGoogleOauth.fulfilled, state => {
      //   state.isLoading = false;
      // })
      // .addCase(connectGoogleOauth.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.errorMessage = action.payload?.message || "Failed to connect Google OAuth.";
      // })

      .addCase(connectFacebookOauth.pending, state => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(connectFacebookOauth.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(connectFacebookOauth.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload?.message || "Failed to connect Facebook OAuth.";
      })

      .addCase(connectFakeGoogleOauth.pending, state => {
        state.isLoading = true;
        state.errorMessage = "";
      })

      .addCase(connectFakeGoogleOauth.fulfilled, state => {
        state.isLoading = false;
      })

      .addCase(connectFakeGoogleOauth.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload?.message || "Failed to connect Fake Google OAuth.";
      })

      .addCase(connectFakeFacebookOauth.pending, state => {
        state.isLoading = true;
        state.errorMessage = "";
      })

      .addCase(connectFakeFacebookOauth.fulfilled, state => {
        state.isLoading = false;
      })

      .addCase(connectFakeFacebookOauth.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload?.message || "Failed to connect Fake Facebook OAuth.";
      });
  }
});

const setError = (state, action) => {
  state.status = false;
  state.isPagesLoading = false;
  state.isLoading = false;
  state.isCompanyLoading = false;
  state.isAccountConnected = false;
  state.isCompanyUpdatedSuccess = false;
  state.isCompanyCreatedSuccess = false;
  state.isAccountLoading = false;
  state.connectionLoading = false;
  state.isUpdatePasswordSuccess = false;
  state.redirectUrl = "";

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

export default appConnectionsSlice.reducer;
