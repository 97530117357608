import { analyticsPath } from "@constants/base-urls";
import { getPermissionURL } from "@helpers/getPermissionURL";
import { openCenteredWindow } from "@helpers/store-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import connectionService from "./service";

export const fetchListOfStatusAccounts = createAsyncThunk(
  "appConnections/fetchListOfStatusAccounts",
  async ({ isSelectCompany, rolesData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await connectionService.fetchListOfStatusAccounts();

      if (isSelectCompany && response?.data) {
        const allFalse = Object.values(response.data).every(value => value === false);
        const redirectURL =
          rolesData.roleName === "Admin" ? "/onboarding/details/" : getPermissionURL(rolesData.permissions);

        window.location.replace(allFalse ? redirectURL : getPermissionURL(rolesData.permissions));
      }

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectAnalytics = createAsyncThunk(
  "appConnections/connectAnalytics",
  async (data, { rejectWithValue }) => {
    try {
      const response = await connectionService.connectAnalytics();

      if (data?.type === "accountSettings") {
        window.localStorage.setItem("accountSettings", JSON.stringify(true));
      } else {
        window.localStorage.removeItem("accountSettings");
      }

      response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectSeo = createAsyncThunk("appConnections/connectSeo", async (data, { rejectWithValue }) => {
  try {
    const response = await connectionService.connectSeo();

    if (data?.type === "accountSettings") {
      window.localStorage.setItem("accountSettings", JSON.stringify(true));
    } else {
      window.localStorage.removeItem("accountSettings");
    }

    response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectFacebook = createAsyncThunk("appConnections/connectAnalytics", async (_, { rejectWithValue }) => {
  try {
    const response = await connectionService.connectFacebook();
    openCenteredWindow(`${analyticsPath}/accounts/facebook/social-media`, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectBusiness = createAsyncThunk("appBusiness/connectBusiness", async (data, { rejectWithValue }) => {
  try {
    const response = await connectionService.connectBusiness();

    if (data?.type === "accountSettings") {
      window.localStorage.setItem("accountSettings", JSON.stringify(true));
    } else {
      window.localStorage.removeItem("accountSettings");
    }

    response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const connectAccount = createAsyncThunk(
  "appConnections/connectAccount",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      await connectionService.connectAccount(code);
      dispatch(fetchListOfStatusAccounts({}));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFacebookAccount = createAsyncThunk(
  "appConnections/connectFacebookAccount",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      await connectionService.connectFacebookAccount(code);
      dispatch(fetchListOfStatusAccounts({}));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectGoogleAds = createAsyncThunk(
  "appConnections/connectBusiness",
  async (data, { rejectWithValue }) => {
    try {
      const response = await connectionService.connectGoogleAds();

      if (data?.type === "accountSettings") {
        window.localStorage.setItem("accountSettings", JSON.stringify(true));
      } else {
        window.localStorage.removeItem("accountSettings");
      }

      response.data.url && openCenteredWindow(response.data.url, "centeredWindow", 1200, 700);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectGoogleOauth = createAsyncThunk(
  "appConnections/connectGoogleOauth",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await connectionService.connectGoogleOauth();

      dispatch(fetchListOfStatusAccounts({}));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFacebookOauth = createAsyncThunk(
  "appFacebook/connectFacebookOauth",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      await connectionService.connectFacebookOauth();

      dispatch(fetchListOfStatusAccounts({}));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFakeGoogleOauth = createAsyncThunk(
  "appConnections/connectFakeGoogleOauth",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await connectionService.connectFakeGoogleOauth();
      dispatch(fetchListOfStatusAccounts({}));
      window.opener.location.reload();
      window.close();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectFakeFacebookOauth = createAsyncThunk(
  "appConnections/connectFakeFacebookOauth",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await connectionService.connectFakeFacebookOauth;

      dispatch(fetchListOfStatusAccounts({}));
      window.opener.location.reload();
      window.close();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
