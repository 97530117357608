import { DIFF_UNKNOWN, SEO_TYPES } from "@constants/types-constants";
import { getSeoRows } from "@helpers/seo-helper";
import { handleError } from "@helpers/store-helper";
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAnalytics,
  fetchAnalyticsSeo,
  fetchPSI,
  fetchSeoWebsites,
  postSeoWebsite,
  resetAnalyticsCache,
  resetSeoCache
} from "./action";

const setAnalytics = (state, action) => {
  state.status = true;
  state.isLoading = false;
  state.analyticsError = false;
  state.isFiltersLoading = false;
  state.analytics = action.payload;

  state.visitorsByCity = action?.payload?.reduce((result, item) => {
    if (item.header === SEO_TYPES.VISITORS_BY_CITY) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.totals = item.total;
      result.rows = getSeoRows(item.reports?.filter(item => !item.name.includes("(not set)")).slice(0, 5));
    }
    return result;
  }, {});

  state.yourVisitors = action?.payload?.reduce((result, item) => {
    if (item.header === SEO_TYPES.YOUR_VISITORS) {
      result.difference = item.diff === DIFF_UNKNOWN || isFinite(item.diff) ? "" : item.diff;
      result.totals = item.total;
      result.graphs = item.graphs;
      result.reports = item.reports;
    }
    return result;
  }, {});
};

const setSeo = (state, action) => {
  state.status = true;
  state.isSeoFiltersLoading = false;
  state.isSeoLoading = false;
  state.competitors = action.payload?.length ? action.payload[0] : [];
  state.GSC = action.payload?.length ? action.payload[1] : [];
  state.PSI = action.payload?.length ? action.payload[2] : [];
  state.keywords = action.payload?.length ? action.payload[3] : [];
};

const setError = (state, action) => {
  if (!state.analyticsError) {
    state.status = false;
    state.isLoading = false;
    state.isFiltersLoading = false;
    state.isAccountConnected = false;
    state.isCompanyUpdatedSuccess = false;
    state.analyticsError = true;

    const message = action?.payload?.response?.data?.message;
    const statusCode = action?.payload?.response?.data?.statusCode;

    state.errorMessage = message;

    handleError(message, statusCode);
  }
};

export const appAnalyticsSlice = createSlice({
  name: "appAnalytics",
  initialState: {
    redirectUrl: "",
    analytics: [],
    seo: {},
    competitors: [],
    GSC: [],
    PSI: [],
    seoWebsites: [],
    visitorsByCity: {},
    yourVisitors: {},
    keywords: {},
    isLoading: false,
    isFiltersLoading: false,
    isSeoFiltersLoading: false,
    isSeoLoading: false,
    status: null,
    analyticsError: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAnalyticsSeo.fulfilled, setSeo)
      .addCase(fetchAnalyticsSeo.pending, state => {
        state.isSeoLoading = true;
        state.isSeoFiltersLoading = true;
      })
      .addCase(resetSeoCache.fulfilled, state => {
        state.status = true;
        state.isSeoFiltersLoading = false;
        state.isSeoLoading = false;
      })
      .addCase(resetSeoCache.pending, state => {
        state.isSeoLoading = true;
        state.isSeoFiltersLoading = true;
      })
      .addCase(resetAnalyticsCache.fulfilled, state => {
        state.status = true;
        state.isFiltersLoading = false;
        state.isLoading = false;
      })
      .addCase(resetAnalyticsCache.pending, state => {
        state.isFiltersLoading = true;
        state.isLoading = true;
      })
      .addCase(fetchAnalytics.fulfilled, setAnalytics)
      .addCase(fetchAnalytics.pending, state => {
        state.isFiltersLoading = true;
        state.isLoading = true;
      })
      .addCase(fetchAnalytics.rejected, state => {
        state.status = false;
        state.isLoading = false;
        state.isFiltersLoading = false;
      })
      .addCase(fetchPSI.fulfilled, (state, action) => {
        state.PSI = action.payload[0];
      })
      .addCase(fetchSeoWebsites.fulfilled, (state, action) => {
        state.seoWebsites = [action.payload];
      })
      .addCase(fetchSeoWebsites.rejected, setError)
      .addCase(resetSeoCache.rejected, setError)
      .addCase(resetAnalyticsCache.rejected, setError)
      .addCase(postSeoWebsite.fulfilled, (state, action) => {
        // Update the `websites` array within the state
        state.seoWebsites[0].websites = state.seoWebsites[0].websites.map(
          website =>
            website.domain === action.payload.domain
              ? { ...website, selected: true } // Match and set selected to true
              : { ...website, selected: false } // Ensure others are false
        );
      })
      .addCase(postSeoWebsite.rejected, setError);
  }
});

export default appAnalyticsSlice.reducer;
