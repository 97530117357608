import {
  COMPANY_NOT_SELECTED,
  PROPERTY_NOT_FOUND,
  PROPERTY_NOT_SELECTED,
  WEBSITE_NOT_SELECTED
} from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { fetchAccountProperties } from "../auth";
import analyticsService from "./service";

// Thunk to fetch analytics SEO data
export const fetchAnalyticsSeo = createAsyncThunk(
  "appAnalytics/fetchAnalyticsSeo",
  async ({ date = { start: 0, end: 0 }, isCompetitors = false, isMarketingPage }, { rejectWithValue, dispatch }) => {
    try {
      const allData =
        !isCompetitors && isMarketingPage
          ? "&data[]=gsc&data[]=psi"
          : !isCompetitors
          ? "&data[]=gsc&data[]=psi&data[]=keywords"
          : "";
      const { start, end } = date;
      const utcStart = start ? moment.utc(start).format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment.utc(end).format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await analyticsService.fetchAnalyticsSeo(startDate, endDate, allData);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === WEBSITE_NOT_SELECTED) {
        await dispatch(fetchSeoWebsites());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk to fetch PSI data
export const fetchPSI = createAsyncThunk(
  "appAnalytics/fetchPSI",
  async ({ date = { start: 0, end: 0 }, isCompetitors = false }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const allData = !isCompetitors ? "&data[]=psi" : "";
      const utcStart = start ? moment.utc(start).format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment.utc(end).format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await analyticsService.fetchPSI(startDate, endDate, allData);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === WEBSITE_NOT_SELECTED) {
        await dispatch(fetchSeoWebsites());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk to fetch analytics data
export const fetchAnalytics = createAsyncThunk(
  "appAnalytics/fetchAnalytics",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment.utc(start).format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment.utc(end).format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (startDate || endDate) {
        const response = await analyticsService.fetchAnalytics(startDate, endDate);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === PROPERTY_NOT_SELECTED || error.response.data.message === PROPERTY_NOT_FOUND) {
        await dispatch(fetchAccountProperties());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk to fetch SEO websites data
export const fetchSeoWebsites = createAsyncThunk("appAnalytics/fetchSeoWebsites", async (_, { rejectWithValue }) => {
  try {
    const response = await analyticsService.fetchSeoWebsites();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Thunk to post SEO website data
export const postSeoWebsite = createAsyncThunk(
  "appAnalytics/postSeoWebsite",
  async ({ domain }, { rejectWithValue, dispatch }) => {
    try {
      const response = await analyticsService.postSeoWebsite(domain);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk reset Seo cache
export const resetSeoCache = createAsyncThunk(
  "appAnalytics/resetSeoCache",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await analyticsService.resetSeoCache();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk reset analytics cache
export const resetAnalyticsCache = createAsyncThunk(
  "appAnalytics/resetAnalyticsCache",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await analyticsService.resetAnalyticsCache();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
