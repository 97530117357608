export const NUMBER_REGEX = /^\d+$/;
export const ROLE_NAME_REGEX = /^.{2,}$/;
export const FLOATING_POINT_REGEX = /^\d+(\.\d+)?$/;
export const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const TEXT_REGEX = /^[A-Za-zЁёА-яа-я\s\u0300-\u036F]+$/;
export const NAME_REGEX = /^[^0-9]*$/;
export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
