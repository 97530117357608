export const EMAIL_REQUIRED = "Email is a required field";
export const ROLE_NAME = "Role name must be longer than or equal to 2 characters";
export const FULL_NAME_REQUIRED = "Full Name is required field";
export const EMAIL_INVALID = "Invalid email address. Please use letters, numbers and dots only.";
export const PASSWORD_LENGTH = "Password must be at least 8 characters";
export const PASSWORD_REQUIRED = "Password is a required field";
export const TERMS_REQUIRED = "You must accept the privacy policy & terms";
export const NOTIFICATIONS_REQUIRED = "You must accept the notifications";
export const REQUIRED_FIELD = "This field is required";
export const INVALID_URL = "Invalid URL format";
export const INVALID_PHONE_NUMBER = "Invalid phone number";
export const INVALID_PASSWORD =
  "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and must be at least 8 characters long.";
export const LETTERS_ONLY = "This field can only contain letters";
export const NUMBERS_ONLY = "This field can only contain numbers";
export const FLOAT_ONLY = "This field can only contain numbers and an optional dot";
export const NOT_CONTAIN_NUMBERS = "Full Name should not contain numbers";
export const MATCH_PASSWORD = "Both the password and confirm password fields value must be matched";
export const MATCH_OLD_PASSWORD = "New password should not match the current password";
export const COMPANY_NAME_LENGTH = "Company name must be longer or equal to 3 characters";
export const MAX_LENGTH = "This field can have a maximum length of 50 characters";
