import axios from "axios";
import { analyticsPath } from "@constants/base-urls";

// Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

// Service methods
const analyticsService = {
  fetchAnalyticsSeo: async (startDate, endDate, allData) => {
    return await axiosInstance.get(`/seo?${startDate}${endDate}&data[]=spyfu${allData}`);
  },

  fetchPSI: async (startDate, endDate, allData) => {
    return await axiosInstance.get(`/seo?${startDate}${endDate}&data[]=spyfu${allData}`);
  },

  fetchAnalytics: async (startDate, endDate) => {
    return await axiosInstance.get(`/analytics?${startDate}${endDate}&data[]=visitorsByCity&data[]=yourVisitors`);
  },

  fetchSeoWebsites: async () => {
    return await axiosInstance.get("/seo/websites");
  },

  postSeoWebsite: async domain => {
    return await axiosInstance.post("/seo/websites", { domain });
  },

  resetSeoCache: async () => {
    return await axiosInstance.get("/seo/reset-cache");
  },

  resetAnalyticsCache: async () => {
    return await axiosInstance.get("/analytics/reset-cache");
  }
};

export default analyticsService;
