import { COMPANY_NOT_SELECTED, SOCIAL_NOT_SELECTED } from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListMyPages } from "../auth";
import reportingService from "./service";

export const fetchWeeklyReport = createAsyncThunk(
  "appOpertationReporting/fetchReport",
  async ({ date }, { rejectWithValue }) => {
    try {
      if (date) {
        const response = await reportingService.fetchWeeklyReport(date);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchMonthlyReport = createAsyncThunk(
  "appOpertationReporting/fetchReport",
  async ({ month, year }, { rejectWithValue }) => {
    try {
      if (month && year) {
        const response = await reportingService.fetchMonthlyReport(year, month);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchQuarterlyReport = createAsyncThunk(
  "appOpertationReporting/fetchReport",
  async ({ quarter, year }, { rejectWithValue }) => {
    try {
      if (quarter && year) {
        const response = await reportingService.fetchQuarterlyReport(year, quarter);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchYearlyReport = createAsyncThunk(
  "appOpertationReporting/fetchReport",
  async ({ year }, { rejectWithValue }) => {
    try {
      if (year) {
        const response = await reportingService.fetchYearlyReport(year);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchCustomReport = createAsyncThunk(
  "appOpertationReporting/fetchReport",
  async (reportCustomDates, { rejectWithValue, dispatch }) => {
    const { currentStartDate, currentEndDate, previousStartDate, previousEndDate } = reportCustomDates;

    try {
      if (reportCustomDates) {
        const response = await reportingService.fetchCustomReport(
          currentStartDate,
          currentEndDate,
          previousStartDate,
          previousEndDate
        );
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === SOCIAL_NOT_SELECTED) {
        await dispatch(getListMyPages());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const printReport = createAsyncThunk("appOpertationReporting/printReport", async (_, { rejectWithValue }) => {
  try {
    const response = await reportingService.printReport();
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
