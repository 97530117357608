import React, { useState, Fragment } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiMenu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "@core/components/icon";

// ** Third Party Components
import PerfectScrollbarComponent from "react-perfect-scrollbar";

// ** Custom Components Imports
import CustomChip from "@core/components/mui/chip";
import CustomAvatar from "@core/components/mui/avatar";

// ** Util Import
import { getInitials } from "@core/utils/get-initials";
import EmptyScreen from "@components/empty-screens/EmptyScreen";
import EmptyNotifications from "@public/images/empty-screens/EmptyNotifications.svg";
import { NOTIFICATION_DESCRIPTION, NOTIFICATION_TITLE } from "@constants/text";

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    width: 380,
    overflow: "hidden",
    marginTop: theme.spacing(4.5),
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  "& .MuiMenu-list": {
    padding: 0,
    "& .MuiMenuItem-root": {
      margin: 0,
      borderRadius: 0,
      padding: theme.spacing(4, 6),
      "&:hover": {
        backgroundColor: theme.palette.action.hover
      }
    }
  }
}));

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 349
});

// ** Styled Avatar component
const Avatar = styled(CustomAvatar)({
  width: 38,
  height: 38,
  fontSize: "1.125rem"
});

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  flex: "1 1 100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginBottom: theme.spacing(0.75)
}));

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: "1 1 100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
});

const ScrollWrapper = ({ children, hidden }) => {
  if (hidden) {
    return <Box sx={{ maxHeight: 349, overflowY: "auto", overflowX: "hidden" }}>{children}</Box>;
  } else {
    return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>;
  }
};

const NotificationDropdown = props => {
  // ** Props
  const { settings, notifications } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState(null);

  // ** Hook
  const hidden = useMediaQuery(theme => theme.breakpoints.down("lg"));

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const RenderAvatar = ({ notification }) => {
    const { avatarAlt, avatarImg, avatarIcon, avatarText, avatarColor } = notification;
    if (avatarImg) {
      return <Avatar alt={avatarAlt} src={avatarImg} />;
    } else if (avatarIcon) {
      return (
        <Avatar skin='light' color={avatarColor}>
          {avatarIcon}
        </Avatar>
      );
    } else {
      return (
        <Avatar skin='light' color={avatarColor}>
          {getInitials(avatarText)}
        </Avatar>
      );
    }
  };

  return (
    <Fragment>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
        <Badge
          color='error'
          variant='dot'
          invisible={!notifications.length}
          sx={{
            "& .MuiBadge-badge": { top: 4, right: 4, boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}` }
          }}
        >
          <Icon fontSize='1.5rem' icon='tabler:bell' />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: "default", userSelect: "auto", backgroundColor: "transparent !important" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography sx={{ cursor: "text", fontWeight: 500 }}>Notifications</Typography>
            <CustomChip
              skin='light'
              size='small'
              color='primary'
              label={`${notifications.length} New`}
              sx={{ height: 20, fontSize: "0.75rem", fontWeight: 500, borderRadius: "10px" }}
            />
          </Box>
        </MenuItem>

        {notifications.length ? (
          [
            <ScrollWrapper key='scroll-wrapper' hidden={hidden}>
              {notifications.map((notification, index) => (
                <MenuItem key={index} disableRipple disableTouchRipple onClick={handleDropdownClose}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <RenderAvatar notification={notification} />
                    <Box
                      sx={{
                        mr: 4,
                        ml: 2.5,
                        flex: "1 1",
                        display: "flex",
                        overflow: "hidden",
                        flexDirection: "column"
                      }}
                    >
                      <MenuItemTitle>{notification.title}</MenuItemTitle>
                      <MenuItemSubtitle variant='body2'>{notification.subtitle}</MenuItemSubtitle>
                    </Box>
                    <Typography variant='body2' sx={{ color: "text.disabled" }}>
                      {notification.meta}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </ScrollWrapper>,
            <MenuItem
              key='read-all'
              disableRipple
              disableTouchRipple
              sx={{
                borderBottom: 0,
                cursor: "default",
                userSelect: "auto",
                backgroundColor: "transparent !important",
                borderTop: theme => `1px solid ${theme.palette.divider}`
              }}
            >
              <Button fullWidth variant='contained' onClick={handleDropdownClose}>
                Read All Notifications
              </Button>
            </MenuItem>
          ]
        ) : (
          <Box sx={{ padding: "24px", marginBottom: "60px" }}>
            <EmptyScreen src={EmptyNotifications} title={NOTIFICATION_TITLE} description={NOTIFICATION_DESCRIPTION} />
          </Box>
        )}
      </Menu>
    </Fragment>
  );
};

export default NotificationDropdown;
