import { COMPANY_NOT_SELECTED, WEBSITE_NOT_SELECTED } from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { default as advertismentService } from "./service";

export const fetchGoogleAdvertisers = createAsyncThunk(
  "appAdvertisment/fetchGoogleAdvertisers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await advertismentService.fetchGoogleAdvertisers();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectGoogleAdvertiser = createAsyncThunk(
  "appAdvertisment/selectGoogleAdvertiser",
  async ({ advertiserId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await advertismentService.selectGoogleAdvertiser(advertiserId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFacebookAdvertisers = createAsyncThunk(
  "appAdvertisment/fetchFacebookAdvertisers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await advertismentService.fetchFacebookAdvertisers();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectFacebookAdvertiser = createAsyncThunk(
  "appAdvertisment/selectFacebookAdvertiser",
  async ({ advertiserId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await advertismentService.selectFacebookAdvertiser(advertiserId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAdsData = createAsyncThunk(
  "appAdvertisment/fetchAdsData",
  async ({ date = { start: 0, end: 0 }, data = [] }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment.utc(start).format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment.utc(end).format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      // Convert data array into query string
      const dataParams = data.map(item => `&data[]=${item}`).join("");

      if (start || end) {
        const response = await advertismentService.fetchAdsData(startDate, endDate, dataParams);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === WEBSITE_NOT_SELECTED) {
        await dispatch(fetchGoogleAdvertisers());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk reset Ads cache
export const resetAdCache = createAsyncThunk(
  "appAdvertisment/resetAdCache",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await advertismentService.resetAdCache();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
