import { COMPANY_NOT_SELECTED, SOCIAL_NOT_SELECTED } from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { getListMyPages } from "../auth";
import socialMediaService from "./service";

export const fetchSocialMedia = createAsyncThunk(
  "appSocialMedia/fetchSocialMedia",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch, getState }) => {
    try {
      const { start, end } = date;
      let utcStart = "";
      // Access Redux state
      const state = getState();
      const selectedFilter = state.daysFilter.filterText;

      if (selectedFilter === "Last 12 months") {
        utcStart = start ? moment.utc(start).add(3, "days").format("YYYY-MM-DD") : "";
      } else {
        utcStart = start ? moment.utc(start).format("YYYY-MM-DD") : "";
      }

      const utcEnd = end ? moment.utc(end).format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await socialMediaService.fetchSocialMedia(startDate, endDate);

        // Check if the response array has the required headers
        const hasRequiredHeaders = response.data.some(
          item => item.header === "fb-insights" && item.header === "ig-insights"
        );

        // If the required headers are not found, dispatch getListMyPages
        if (!hasRequiredHeaders) {
          await dispatch(getListMyPages());
        }

        return response.data;
      }
    } catch (error) {
      if (error.response?.data?.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else if (error.response?.data?.message === SOCIAL_NOT_SELECTED) {
        await dispatch(getListMyPages());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk reset analytics cache
export const resetSocialMediaCache = createAsyncThunk(
  "appSocialMedia/resetSocialMediaCache",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await socialMediaService.resetSocialMediaCache();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
